import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('manual-painel-vendemmia')
export class ManualMigracao extends LitElement {
  static get styles() {
    return [
      css`
        :host {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        body {
          color: #fff;
        }
        iframe {
          display: block;
          margin: 0 auto;
        }
        a {
          cursor: pointer;
          color: #fff;
          text-decoration: none;
        }
        button {
          padding: 10px 20px;
          font-size: 19px;
          text-decoration: none;
          background-color: #a885f9;
          color: #fff;
          border: none;
          border-radius: 52px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          margin-top: 10px;
        }

        button:hover {
          background-color: #a885f96e;
        }
      `,
    ];
  }

  constructor() {
    super();
  }

  @property()
  height: number = 920;
  width: number = 740;

  @state()
  calculated?: boolean = false;

  async updated() {
    let idealHeight = this.height;
    let idealWidth = this.width;

    let currentHeight = document.documentElement.clientHeight;
    let currentWidth = document.documentElement.clientWidth;

    if (currentHeight < idealHeight && currentWidth < idealWidth) {
      let percentHeight = currentHeight / idealHeight;
      let percentWidth = currentWidth / idealWidth;
      if (percentHeight > percentWidth) {
        // Largura é limitante
        this.width = currentWidth;
        this.height = percentHeight * idealHeight;
      } else {
        // Altura é limitante
        this.height = currentHeight;
        this.width = percentWidth * idealWidth;
      }
    } else if (currentHeight < idealHeight) {
      // Somente altura é menor
      this.height = currentHeight;
    } else if (currentWidth < idealWidth) {
      // Somente a largura é menor
      this.width = currentWidth;
    }

    this.calculated = true;
  }

  render() {
    return this.calculated
      ? html`
          <iframe
            src="https://indd.adobe.com/embed/ed244ba2-0ff8-43d4-af54-21a49f1e0948?startpage=1&allowFullscreen=false"
            width="${this.width}"
            height="${this.height - (this.height * 0.10)}"
            frameborder="0"
            allowfullscreen=""
          ></iframe>

          <button>
            <a
              href="/assets/guides/manual-painel-vendemmia.pdf"
              target="_blank"
            >
              Baixar
            </a>
          </button>
        `
      : 'Carregando...';
  }
}
