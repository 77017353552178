import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('apresentacao-vendemmia')
export class ApresentacaoVendemmia extends LitElement {
  static get styles() {
    return [
      css`
        body {
          color: #fff;
        }
        iframe {
          display: block;
          margin: 0 auto;
        }
      `,
    ];
  }

  constructor() {
    super();
  }

  @property()
  height?: number = 932;
  width?: number = 430;

  @state()
  calculated?: boolean = false;

  async updated() {
    let idealHeight = 932;
    let idealWidth = 430;

    let currentHeight = document.documentElement.clientHeight;
    let currentWidth = document.documentElement.clientWidth;

    if (currentHeight < idealHeight && currentWidth < idealWidth) {
      let percentHeight = currentHeight / idealHeight;
      let percentWidth = currentWidth / idealWidth;
      if (percentHeight > percentWidth) {
        // Largura é limitante
        this.width = currentWidth;
        this.height = percentHeight * idealHeight;
      } else {
        // Altura é limitante
        this.height = currentHeight;
        this.width = percentWidth * idealWidth;
      }
    } else if (currentHeight < idealHeight) {
      // Somente altura é menor
      this.height = currentHeight;
    } else if (currentWidth < idealWidth) {
      // Somente a largura é menor
      this.width = currentWidth;
    }

    this.calculated = true;
  }

  render() {
    return this.calculated
      ? html`
          <iframe
            id="presentation"
            width="${this.width}"
            height="${this.height}"
            src="https://xd.adobe.com/embed/58a2774b-4fb9-4028-938d-caf39f6c3124-0df1/?fullscreen"
            frameborder="0"
            allowfullscreen
          ></iframe>
        `
      : 'Carregando...';
  }
}
