import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}

import { Router } from '@thepassle/app-tools/router.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/app-home.js';
import './pages/apresentacao-vendemmia.ts';
import './pages/manual-painel-vendemmia.ts';
import './pages/manual-painel-vendemmia-migracao.ts';
import './pages/manual-motorista.ts';
import './pages/manual-motorista-simplificado.ts';

// const baseURL: string = (import.meta as any).env.BASE_URL;

export const router = new Router({
    routes: [
      {
        path: '/apresentacao-vendemmia',
        title: 'Conheça a Vendemmia',
        render: () => html`<apresentacao-vendemmia></apresentacao-vendemmia>`
      },
      {
        path: '/manual-painel-vendemmia',
        title: 'Manual do Painel Vendemmia',
        render: () => html`<manual-painel-vendemmia></manual-painel-vendemmia>`
      },
      {
        path: '/manual-painel-vendemmia-migracao',
        title: 'Manual de migração',
        render: () => html`<manual-painel-vendemmia-migracao></manual-painel-vendemmia-migracao>`
      },
      {
        path: '/manual-motorista',
        title: 'Manual do App do Motorista (simplificado)',
        render: () => html`<manual-motorista></manual-motorista>`
      },
      {
        path: '/manual-motorista-simplificado',
        title: 'Manual do App do Motorista (simplificado)',
        render: () => html`<manual-motorista-simplificado></manual-motorista-simplificado>`
      },
      {
        path: '/',
        title: 'Vendemmia Logística Integrada',
        render: () => html`<app-home></app-home>`
      },
    ]
  });
