import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('app-home')
export class AppHome extends LitElement {
  static get styles() {
    return css`
      :host {
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
      }

      a {
        text-decoration: none;
      }

      button {
        padding: 10px 20px;
        font-size: 19px;
        text-decoration: none;
        background-color: #a885f9; /* Button background color, you can change it */
        color: #fff; /* Button text color, you can change it */
        border: none;
        border-radius: 52px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }

      button:hover {
        background-color: #a885f96e; /* Button background color on hover, you can change it */
      }
    `;
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <a href="/apresentacao-vendemmia"
        ><button>Apresentação Vendemmia</button></a
      >
      <a href="/manual-painel-vendemmia"
        ><button>Manual: Painel Vendemmia</button></a
      >
      <a href="/manual-painel-vendemmia-migracao"
        ><button>Manual: migrando para a nova versão</button></a
      >
      <a href="/manual-motorista"
        ><button>Manual: App do motorista (completo)</button></a
      >
      <a href="/manual-motorista-simplificado"
        ><button>Manual: App do motorista: (simplificado)</button></a
      >
    `;
  }
}
